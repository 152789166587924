<script setup lang="ts">
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from 'moment/moment'
import { confirmedActionsCreator } from '@/utils/admin/actions'
import InvestmentDrawer from '@/views/admin/demo/InvestmentDrawer.vue'
import AppPagination from '@/components/AppPagination.vue'
import AffiliateFormDrawer from '@/views/admin/demo/AffiliateFormDrawer.vue'
import type { DemoAffiliateModel } from '@api/models/demo'
import type { LengthAwarePaginatorResponse } from '@api/responses'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const perPage = ref(50)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const searchQuery = ref('')
const formDialog = ref(false)
const investmentDrawerOpen = ref(false)
const affiliate = ref<DemoAffiliateModel | undefined>()

const headers = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Joined At',
    key: 'joined_at',
  },
  {
    title: 'Created At',
    key: 'created_at',
  },
  {
    title: 'Actions',
    key: 'actions',
  },
]

const { data: responseData, execute: fetchAffiliates, isFetching } = await useApi<LengthAwarePaginatorResponse<DemoAffiliateModel>>(createUrl('/admin/demo/affiliates', {
  query: {
    perPage,
    page,
    sortBy,
    orderBy,
    q: searchQuery,
  },
}))

const createConfirmAction = confirmedActionsCreator(fetchAffiliates)

const createAffiliate = () => {
  affiliate.value = undefined
  formDialog.value = true
}

const editAffiliate = (_affiliate: DemoAffiliateModel) => {
  affiliate.value = _affiliate
  formDialog.value = true
}

const deleteAffiliate = createConfirmAction('Are you sure you want to delete this affiliate ?', async (_affiliate: DemoAffiliateModel) => {
  const res = await $api(`/admin/demo/affiliates/${_affiliate.id}`, {
    method: 'DELETE',
  })

  if (!res.status) {
    Swal.fire({
      title: 'Error!',
      text: res.error,
      icon: 'error',
      confirmButtonText: 'Got it',
    })
  }
})

const affiliates = computed(() => responseData.value?.data)
const total = computed(() => responseData.value?.total ?? 0)

const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}
</script>

<template>
  <section>
    <VCard :loading="isFetching">
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <VBtn
            prepend-icon="tabler-refresh"
            :elevation="0"
            @click="fetchAffiliates"
          >
            Refresh
          </VBtn>
          <AppSelect
            :model-value="perPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="perPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-bot-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>
          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

          <VBtn
            prepend-icon="tabler-plus"
            @click="investmentDrawerOpen = true"
          >
            Add New Affiliate Investment
          </VBtn>

          <!-- 👉 Add affiliate button -->
          <VBtn
            prepend-icon="tabler-plus"
            @click="createAffiliate"
          >
            Add New Affiliate
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <VDataTableServer
        v-model:items-per-page="perPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="affiliates"
        :headers="headers"
        :items-length="total"
        show-select
        return-object
        :loading="isFetching"
        :value-comparator="(affiliate1: DemoAffiliateModel, affiliate2: DemoAffiliateModel) => affiliate1.id === affiliate2.id"
        @update:options="updateOptions"
      >
        <template #item.joined_at="{ item }">
          {{ moment(item.joined_at).format('YYYY-MM-DD') }}
        </template>
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </template>
        <!-- User -->

        <!-- Actions -->
        <template #item.actions="{ item }">
          <div class="d-flex items-center">
            <VTooltip text="Edit Affiliate">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="warning"
                  @click="editAffiliate(item)"
                >
                  <VIcon icon="tabler-edit" />
                </IconBtn>
              </template>
            </VTooltip>
            <VTooltip text="Delete Affiliate">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="error"
                  @click="deleteAffiliate(item)"
                >
                  <VIcon icon="tabler-square-x" />
                </IconBtn>
              </template>
            </VTooltip>
          </div>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <AppPagination
            v-model="page"
            :per-page="perPage"
            :total="total"
          />
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <AffiliateFormDrawer
    v-model="formDialog"
    :affiliate="affiliate"
    @success="fetchAffiliates"
  />
  <InvestmentDrawer v-model="investmentDrawerOpen" />
</template>
