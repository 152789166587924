<script setup lang="ts">
import moment from 'moment'
import ExchangeSettingsDialog from '@/views/admin/exchanges/ExchangeSettingsDialog.vue'
import SellingSignalDialog from '@/views/admin/exchanges/SellingSignalDialog.vue'
import BuyingSignalDialog from '@/views/admin/exchanges/BuyingSignalDialog.vue'
import PairsDialog from '@/views/admin/exchanges/PairsDialog.vue'
import { useCrud } from '@/pages/crud'
import { paginationMeta } from '@/@api/utils/paginationMeta'
import ExchangeDrawer from '@/views/admin/exchanges/ExchangeDrawer.vue'
import type {
  Exchange,
  ExchangeProperties,
} from '@/@api/models/exchange'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const searchQuery = ref('')
const itemsPerPage = ref(50)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const showPairsDialog = ref(false)
const showBuyingSignalDialog = ref(false)
const showSellingSignalDialog = ref(false)
const showSettingsDialog = ref(false)
const exchange = ref<Exchange | undefined >()

const headers = [
  { title: 'Name', key: 'name' },
  { title: 'Code', key: 'code' },
  { title: 'Status', key: 'status' },
  {
    title: 'Created',
    key: 'created_at',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

interface PaginatedData<T> {
  current_page: number
  from: number
  to: number
  per_page: number
  total: number
  data: T[]
}

const { data: responseData, execute: fetchExchanges }
  = await useApi<PaginatedData<Exchange>>(
    createUrl('/admin/exchanges', {
      query: {
        q: searchQuery,
        per_page: itemsPerPage,
        page,
        sortBy,
        orderBy,
      },
    }),
  )

const crud = useCrud<Exchange, ExchangeProperties>(
  '/admin/exchanges',
  fetchExchanges,
)

// Update data table options
const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const exchangesData = computed(
  () => responseData.value?.data,
)

const total = computed(() => responseData.value?.total ?? 0)

const updateStatus = async (ex: Exchange) => {
  await $api(`/admin/exchanges/${ex.id}/status`, {
    method: 'PUT',
    body: {
      status:
        ex.status === 'Active'
          ? 'Inactive'
          : 'Active',
    },
  })
  fetchExchanges()
}

const openPairsDialog = (ex: Exchange) => {
  exchange.value = ex
  showPairsDialog.value = true
}

const openBuyingSignalDialog = (ex: Exchange) => {
  exchange.value = ex
  showBuyingSignalDialog.value = true
}

const openSellingSignalDialog = (ex: Exchange) => {
  exchange.value = ex
  showSellingSignalDialog.value = true
}

const resetExchange = () => {
  exchange.value = undefined
}

const openSettingsDialog = (ex: Exchange) => {
  showSettingsDialog.value = true
  exchange.value = ex
}
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              {
                value: 10,
                title: '10',
              },
              {
                value: 25,
                title: '25',
              },
              {
                value: 50,
                title: '50',
              },
              {
                value: 100,
                title: '100',
              },
              {
                value: -1,
                title: 'All',
              },
            ]"
            style="inline-size: 6.25rem"
            @update:model-value="
              itemsPerPage = parseInt($event, 10)
            "
          />
        </div>
        <VSpacer />

        <div class="d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

          <!-- 👉 Add exchange button -->
          <VBtn
            prepend-icon="tabler-plus"
            @click="crud.create"
          >
            Add New Exchange
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="exchangesData"
        :headers="headers"
        :items-length="total"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <span :style="`color: ${item.color}`">
            {{ item.name }}
          </span>
        </template>
        <template #item.status="{ item }">
          <VChip
            :color="
              item.status === 'Active' ? 'success' : 'error'
            "
          >
            {{ item.status }}
          </VChip>
        </template>
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </template>
        <!-- Exchange -->

        <!-- Actions -->
        <template #item.actions="{ item }">
          <VTooltip
            :text="
              `${item.status === 'Active'
                ? 'Deactivate'
                : 'Activate'} Exchange`
            "
          >
            <template #activator="{ props }">
              <IconBtn
                v-bind="props"
                @click="updateStatus(item)"
              >
                <VIcon
                  :icon="`tabler-eye${
                    item.status === 'Active'
                      ? ''
                      : '-closed'
                  }`"
                />
              </IconBtn>
            </template>
          </VTooltip>
          <VTooltip text="Exchange Settings">
            <template #activator="{ props }">
              <IconBtn
                v-bind="props"
                @click="openSettingsDialog(item)"
              >
                <VIcon icon="tabler-settings" />
              </IconBtn>
            </template>
          </VTooltip>
          <VTooltip text="Edit Exchange">
            <template #activator="{ props }">
              <IconBtn
                v-bind="props"
                @click="crud.edit(item)"
              >
                <VIcon icon="tabler-edit" />
              </IconBtn>
            </template>
          </VTooltip>
          <VTooltip text="Delete Exchange">
            <template #activator="{ props }">
              <IconBtn
                v-bind="props"
                @click="crud.deleteItem(item.id)"
              >
                <VIcon icon="tabler-trash" />
              </IconBtn>
            </template>
          </VTooltip>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{
                paginationMeta(
                  {
                    page,
                    itemsPerPage,
                  },
                  total,
                )
              }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(total / itemsPerPage)"
              :total-visible="
                $vuetify.display.xs
                  ? 1
                  : Math.ceil(total / itemsPerPage)
              "
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <ExchangeDrawer
    v-model:isDrawerOpen="crud.createComponent.value"
    :exchange="crud.model.value"
    @exchange-data="crud.drawerUpdate"
  />
  <ExchangeSettingsDialog
    v-model="showSettingsDialog"
    :exchange="exchange"
  />
  <PairsDialog
    v-model="showPairsDialog"
    :exchange="exchange"
  />
  <BuyingSignalDialog
    v-model="showBuyingSignalDialog"
    :exchange="exchange"
    @success="resetExchange"
  />

  <SellingSignalDialog
    v-model="showSellingSignalDialog"
    :exchange="exchange"
    @success="resetExchange"
  />
  <ConfirmDialog
    v-model="crud.deleteConfirmation.value"
    content="Are you sure you want to delete this exchange?"
    @confirm="crud.destroy"
  />
  <VDialog
    v-model="crud.loading.value"
    :scrim="false"
    persistent
    width="auto"
  >
    <VCard color="primary">
      <VCardText>
        Please stand by
        <VProgressLinear
          indeterminate
          color="white"
          class="mb-0"
        />
      </VCardText>
    </VCard>
  </VDialog>
</template>
