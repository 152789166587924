<script setup lang="ts">
import moment from 'moment'
import AdminDrawer from '@/views/admin/admins/AdminDrawer.vue'
import type { Admin, AdminProperties } from '@api/models/admin'
import type { LengthAwarePaginatorResponse } from '@api/responses'
import { paginationMeta } from '@/@api/utils/paginationMeta'
import { useCrud } from '@/pages/crud'
import { confirmedActionsCreator } from '@/utils/admin/actions'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const searchQuery = ref('')
const itemsPerPage = ref(50)
const archived = ref(0)
const user = ref<Admin | undefined>()

const { data: responseData, execute: fetchAdmins, isFetching } = await useApi<LengthAwarePaginatorResponse<Admin>>(createUrl('/admin/admins', {
  query: {
    q: searchQuery,
    per_page: itemsPerPage,
    page,
    sortBy,
    orderBy,
    archived,
  },
}))

const crud = useCrud<Admin, AdminProperties>('/admin/admins', fetchAdmins)

const headers = [
  { title: 'Name', key: 'name' },
  { title: 'Email', key: 'email' },
  { title: 'Created', key: 'created_at' },
  { title: 'Actions', key: 'actions', sortable: false },
]

// Update data table options
const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const refreshDrawer = () => {
  user.value = undefined
  fetchAdmins()
}

const usersData = computed(() => responseData.value?.data)
const total = computed(() => responseData.value?.total ?? 0)
const createConfirmAction = confirmedActionsCreator(fetchAdmins)

const unbanAdmin = createConfirmAction((_admin: Admin) => `Are you sure you want to unban ${_admin.name}`,
  (_admin: Admin) => $api(`/admin/admins/${_admin.id}/unban`, {
    method: 'PUT',
  }))

const banAdmin = createConfirmAction((_admin: Admin) => `Are you sure you want to ban ${_admin.name}`,
  (_admin: Admin) => $api(`/admin/admins/${_admin.id}/ban`, {
    method: 'PUT',
  }))

const deleteAdmin = createConfirmAction(
  (_admin: Admin) => `Are you sure you want to delete ${_admin.name}`,
  (_admin: Admin) => $api(
    `/admin/admins/${_admin.id}`,
    {
      method: 'DELETE',
    },
  ),
)
</script>

<template>
  <section>
    <VCard :loading="isFetching">
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <VBtn
            prepend-icon="tabler-refresh"
            :elevation="0"
            @click="fetchAdmins"
          >
            Refresh
          </VBtn>
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>
          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

          <!-- 👉 Add user button -->
          <VBtn
            prepend-icon="tabler-plus"
            @click="crud.create"
          >
            Add New Admin
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="usersData"
        :headers="headers"
        :items-length="total"
        :loading="isFetching"
        @update:options="updateOptions"
      >
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </template>
        <!-- Admin -->

        <!-- Actions -->
        <template #item.actions="{ item }">
          <VTooltip text="Edit Admin">
            <template #activator="{ props }">
              <IconBtn
                v-bind="props"
                @click="crud.edit(item)"
              >
                <VIcon icon="tabler-edit" />
              </IconBtn>
            </template>
          </VTooltip>
          <VTooltip text="Activity Logs">
            <template #activator="{ props }">
              <IconBtn v-bind="props" :to="`/admin/admins/${item.id }/logs`">
                <VIcon icon="tabler-file-text" />
              </IconBtn>
            </template>
          </VTooltip>
          <VTooltip :text="item.banned_at ? 'Unban Admin' : 'Ban Admin'">
            <template #activator="{ props }">
              <IconBtn
                v-if="item.banned_at"
                v-bind="props"
                color="success"
                @click="unbanAdmin(item)"
              >
                <VIcon icon="tabler-key" />
              </IconBtn>
              <IconBtn
                v-else
                v-bind="props"
                color="error"
                @click="banAdmin(item)"
              >
                <VIcon icon="tabler-key-off" />
              </IconBtn>
            </template>
          </VTooltip>
          <VTooltip text="Delete Admin">
            <template #activator="{ props }">
              <IconBtn
                v-bind="props"
                color="error"
                @click="deleteAdmin(item)"
              >
                <VIcon icon="tabler-trash" />
              </IconBtn>
            </template>
          </VTooltip>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, total) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(total / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(total / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <AdminDrawer
    v-model="crud.createComponent.value"
    :admin="crud.model.value"
    @update="refreshDrawer"
    @admin-data="crud.drawerUpdate"
  />
  <!-- Dialog -->
  <ConfirmDialog
    v-model="crud.deleteConfirmation.value"
    content="Are you sure you want to delete this user?"
    @confirm="crud.destroy"
  />
  <VDialog
    v-model="crud.loading.value"
    :scrim="false"
    persistent
    width="auto"
  >
    <VCard color="primary">
      <VCardText>
        Please stand by
        <VProgressLinear
          indeterminate
          color="white"
          class="mb-0"
        />
      </VCardText>
    </VCard>
  </VDialog>
</template>
