import { useStorage } from '@vueuse/core'
import type { Channel } from 'laravel-echo'
import { router } from '@/plugins/2.router'
import { echo } from '@/plugins/echo'
import { Auth } from '@api'
import type { User } from '@api/models/user'
import type { RegisterRequest, ResetPasswordRequest, VerifyEmailRequest } from '@api/requests/auth'
import { useAccountsStore } from '@stores/accounts'
import { useHomeStore } from '@stores/home'

export const useAuthStore = defineStore('auth', () => {
  const $echo = ref<Channel | undefined>()

  const user = useStorage<User | null>('userData', null, undefined, {
    serializer: {
      read: (v: any) => (v ? JSON.parse(v) : null),
      write: (v: any) => JSON.stringify(v),
    },
  })

  const accountsStore = useAccountsStore()
  const homeStore = useHomeStore()

  const twoFactorEnabled = computed(() => user.value?.two_factor_secret && user.value.two_factor_confirmed_at)

  const isAuth: ComputedRef<boolean> = computed(() => !!user.value)

  const login = async (loginData: { email: string; password: string }) => {
    const res = await Auth.login(loginData)

    void accountsStore.$init()
    void homeStore.$init()

    return res
  }

  const register = async (registerData: RegisterRequest) => {
    const res = await Auth.register(registerData)

    return res.status
  }

  const forgotPassword = async (forgotPasswordData: { email: string }) => {
    return Auth.forgotPassword(forgotPasswordData)
  }

  const resetPassword = async (resetPasswordData: ResetPasswordRequest) => {
    return Auth.resetPassword(resetPasswordData)
  }

  const verifyEmail = async (verifyEmailData: VerifyEmailRequest) => {
    const res = await Auth.verifyEmail(verifyEmailData)

    user.value = res.user

    return res
  }

  const resetEmailChange = async () => {
    const res = await Auth.resetEmailChange()

    user.value = res.user

    return res
  }

  const updateProfile = async (updateProfileData: FormData) => {
    const res = await Auth.updateProfile(updateProfileData)

    user.value = res.user

    return res
  }

  const resetProfile = async () => {
    const res = await Auth.resetProfile()

    user.value = res.user

    return true
  }

  const resendVerificationEmail = async () => {
    return Auth.resendVerificationEmail()
  }

  const localLogout = () => {
    console.log('Local logout called')
    user.value = undefined
    $echo.value = undefined
    void router.replace('/login')
  }

  const logout = async () => {
    try {
      await Auth.logout()
    }
    catch (e) { }

    useAccountsStore().$reset()
    useHomeStore().$reset()
    localLogout()
  }

  const getUser = async () => {
    const res = await Auth.user()

    user.value = res.user

    return res.status
  }

  const getStatus = async () => {
    return Auth.getStatus()
  }

  const enable2FA = async () => {
    await Auth.enable2FA()
    void getUser()
  }

  const disable2FA = async () => {
    await Auth.disable2FA()
    void getUser()
  }

  const checkEcho = () => {
    if (!$echo.value)
      $echo.value = echo.private(`App.Models.User.${user.value?.id}`)
  }

  const checkUser = (newUser: User | null = null) => {
    if (user.value || newUser)
      checkEcho()
  }

  const onInit = () => {
    checkUser()
  }

  onInit()

  watch(user, newVal => {
    checkUser(newVal)
  })

  return {
    user,
    isAuth,
    login,
    register,
    verifyEmail,
    resendVerificationEmail,
    resetEmailChange,
    forgotPassword,
    resetPassword,
    updateProfile,
    resetProfile,
    logout,
    localLogout,
    getUser,
    enable2FA,
    disable2FA,
    getStatus,
    twoFactorEnabled,
    $echo,
  }
})
