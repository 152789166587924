<script setup lang="ts">
import { errorDialogState } from '@/composables/error'

const close = () => errorDialogState.show = false
</script>

<template>
  <VDialog
    v-model="errorDialogState.show"
    :max-width="440"
  >
    <DialogCloseBtn @click="close" />
    <VCard class="text-center px-10 py-6">

      <VCardText>
        <VIcon icon="circle-x" />
        {{ errorDialogState.content }}
      </VCardText>

      <VCardText class="d-flex align-center justify-center gap-2">
        <VBtn
          variant="elevated"
          @click="close"
        >
          Got it
        </VBtn>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style scoped lang="scss">

</style>
