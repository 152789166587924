<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'
import { ValidationError } from '@api/errors'
import { router } from '@/plugins/2.router'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import { useAuthStore } from '@/stores/auth'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'

import authV2ResetPasswordIllustrationDark from '@images/pages/auth-v2-reset-password-illustration-dark.png'
import authV2ResetPasswordIllustrationLight from '@images/pages/auth-v2-reset-password-illustration-light.png'
import authV2MaskDark from '@images/pages/misc-mask-dark.png'
import authV2MaskLight from '@images/pages/misc-mask-light.png'

definePage({
  name: 'reset-password',
  path: '/reset-password/:token',
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const route = useRoute('reset-password')

const authThemeImg = useGenerateImageVariant(authV2ResetPasswordIllustrationLight,
  authV2ResetPasswordIllustrationDark,
)

const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)

const authStore = useAuthStore()

const state = reactive({
  token: (route.params as any).token,
  email: route.query.email as string,
  password: '',
  password_confirmation: '',
})

const rules = {
  password: { required },
  password_confirmation: { required, sameAsPassword: sameAs(computed(() => state.password)) },
}

const $externalResults = ref({})

const v$ = useVuelidate(rules, state)

const loading = ref(false)
const error = ref('')
const message = ref('')

const submit = async () => {
  try {
    loading.value = true
    error.value = ''
    message.value = ''

    const res = await authStore.resetPassword(state)

    console.log(res)
    message.value = res.message

    loading.value = false
    await nextTick(() => {
      router.replace('/login')
    })
  }
  catch (err) {
    if (err instanceof ValidationError) {
      error.value = err.message
      $externalResults.value = err.errors ?? {}
    }

    loading.value = false
  }
}
</script>

<template>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <VImg
            max-width="400"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <VImg
          class="auth-footer-mask"
          :src="authThemeMask"
        />
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <VNodeRenderer
            :nodes="themeConfig.app.logo"
            class="mb-6"
          />

          <h4 class="text-h4 mb-1">
            Reset Password 🔒
          </h4>
          <p class="mb-0">
            for <span class="font-weight-bold">{{ state.email }}</span>
          </p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="submit">
            <VScaleTransition>
              <VRow v-if="message">
                <VCol cols="12">
                  <VAlert

                    variant="tonal"
                    color="success"
                  >
                    {{ message }}
                  </VAlert>
                </VCol>
              </VRow>
            </VScaleTransition>
            <VScaleTransition>
              <VRow v-if="error">
                <VCol cols="12">
                  <VAlert

                    variant="tonal"
                    color="error"
                  >
                    {{ error }}
                  </VAlert>
                </VCol>
              </VRow>
            </VScaleTransition>
            <VRow>
              <!-- password -->
              <VCol cols="12">
                <AppPasswordField
                  v-model="v$.password.$model"
                  :field="v$.password"
                  :disabled="loading"
                  autofocus
                  label="New Password"
                  placeholder="············"
                />
              </VCol>

              <!-- Confirm Password -->
              <VCol cols="12">
                <AppPasswordField
                  v-model="v$.password_confirmation.$model"
                  :field="v$.password_confirmation"
                  :disabled="loading"
                  label="Confirm Password"
                  placeholder="············"
                />
              </VCol>

              <!-- Set password -->
              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                  :loading="loading"
                >
                  Set New Password
                </VBtn>
              </VCol>

              <!-- back to login -->
              <VCol cols="12">
                <RouterLink
                  class="d-flex align-center justify-center"
                  :to="{ name: 'login' }"
                >
                  <VIcon
                    icon="tabler-chevron-left"
                    class="flip-in-rtl"
                  />
                  <span>Back to login</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style scoped lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
