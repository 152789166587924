<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { RouterLink } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import LoginAnimation from '../lottie/Login.json'
import { router } from '@/plugins/2.router'
import { useAuthStore } from '@/stores/auth'
import { ValidationError } from '@api/errors'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'

definePage({
  meta: {
    layout: 'blank',
  },
})

const authStore = useAuthStore()

const loading = ref(false)

const form = reactive({
  email: '',
  password: '',
  remember: false,
})

const rules = {
  email: { required },
  password: { required },
}

const error = ref('')
const $externalResults = ref({})

const v$ = useVuelidate(rules, form, { $externalResults })

const login = async () => {
  v$.value.$clearExternalResults()
  if (!(await v$.value.$validate()))
    return

  try {
    loading.value = true
    error.value = ''

    const res = await authStore.login(form)
    if (res.two_factor) {
      await nextTick(() => {
        router.replace({ name: 'check2fa' })
      })
    }
    else {
      console.log('LoggedIn')
      await authStore.getUser()
      await nextTick(() => {
        router.replace('/user')
      })
    }
    console.log(res)
  }
  catch (err) {
    if (err instanceof ValidationError) {
      error.value = err.message
      $externalResults.value = err.errors ?? {}
    }

    loading.value = false
  }
}
</script>

<template>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <div class="back-link">
            <RouterLink to="/" class="d-flex gap-x-3 align-center">
              <img src="/icon.png" alt="Tradeasy Home" width="50" height="50">
              <h4 data-v-a23acb87="" class="text-h4 text-capitalize text-truncate font-weight-bold">tradeasy</h4>
            </RouterLink>
          </div>
          <Vue3Lottie
            :animation-data="LoginAnimation"
            class="login"
          />
          <!--
            <VImg
            max-width="505"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
            />
          -->
        </div>

        <!--
          <VImg
          class="auth-footer-mask"
          :src="authThemeMask"
          />
        -->
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <VNodeRenderer
            :nodes="themeConfig.app.logo"
            class="mb-6"
          />
          <h4 class="text-h4 mb-1">
            {{ $t('pages.login.welcome_to') }} <span class="text-capitalize">{{ themeConfig.app.title }}</span>! 👋🏻
          </h4>
          <p class="mb-0">
            {{ $t('pages.login.subtitle') }}
          </p>
        </VCardText>
        <VCardText>
          <VForm @submit.prevent="login">
            <VScaleTransition>
              <VRow v-if="error">
                <VCol cols="12">
                  <VAlert
                    variant="tonal"
                    color="error"
                  >
                    {{ error }}
                  </VAlert>
                </VCol>
              </VRow>
            </VScaleTransition>
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="v$.email.$model"
                  :field="v$.email"
                  :disabled="loading"
                  autofocus
                  :label="$t('fields.email')"
                  type="email"
                  placeholder="johndoe@email.com"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <AppPasswordField
                  v-model="v$.password.$model"
                  :field="v$.password"
                  :disabled="loading"
                  :label="$t('fields.password')"
                  placeholder="············"
                />

                <div class="d-flex align-center flex-wrap justify-space-between mt-2 mb-4">
                  <VCheckbox
                    v-model="form.remember"
                    :label="$t('fields.remember_me')"
                  />
                  <RouterLink
                    class="text-primary ms-2 mb-1"
                    to="/forgot-password"
                  >
                    {{ $t('pages.login.forgot_password') }}
                  </RouterLink>
                </div>

                <VBtn
                  block
                  type="submit"
                  :loading="loading"
                >
                  {{ $t('pages.login.login') }}
                </VBtn>
              </VCol>

              <!-- create account -->
              <VCol
                cols="12"
                class="text-center text-base"
              >
                <span>{{ $t('pages.login.new') }}</span>

                <RouterLink
                  class="text-primary ms-2"
                  to="/register"
                >
                  {{ $t('pages.login.create_account') }}
                </RouterLink>
              </VCol>

              <!--
                <VCol
                cols="12"
                class="d-flex align-center"
                >
                <VDivider />

                <span class="mx-4">{{ $t('pages.login.or') }}</span>

                <VDivider />
                </VCol>
              -->

              <!-- auth providers -->
              <!--
                <VCol
                cols="12"
                class="text-center"
                >
                <AuthProvider />
                </VCol>
              -->
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";

.login {
  filter: hue-rotate(236deg);
}

.back-link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 10px 20px;
}
</style>
