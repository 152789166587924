import type { FetchRequest, FetchResponse } from 'ofetch'
import { ofetch } from 'ofetch'
import { toast } from 'vuetify-sonner'
import { router } from '@/plugins/2.router'
import { useAuthStore } from '@/stores/auth'
import { ClientError, ValidationError } from '@/@api/errors'

const checkAuthorization = async ({ request, response }: {
  request: FetchRequest
  response: FetchResponse<any>
}) => {
  if (request.toString().includes('logout'))
    return
  if (response.status === 401) {
    const authStore = useAuthStore()
    try {
      await authStore.logout()
    }
    catch (e) {}
    if (request.toString().includes('admin'))
      void router.replace('/admin/login')
    else
      void router.replace('/login')
  }
}

export const $api = ofetch.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || '/api',
  credentials: 'include',
  ignoreResponseError: false,
  headers: {
    Accept: 'application/json',
  },

  async onRequest({ options }) {
    const token = useCookie('XSRF-TOKEN').value
    const headers: any = { ...options.headers }
    if (token)
      headers['X-XSRF-TOKEN'] = token

    headers.Accept = 'application/json'

    options.headers = headers
  },

  async onResponse({ request, response }) {
    void checkAuthorization({ request, response })
    if (response._data.status && response._data.message)
      toast.success(response._data.message)
  },
  async onResponseError({ request, response }): Promise<void> {
    void checkAuthorization({ request, response })

    if (response.status.toString().slice(0, 1) === '5')
      return
    const data = await response._data
    if (Object.hasOwn(data, 'message'))
      toast.error(data.message)

    if (response.status === 422)

      throw new ValidationError(data)
    else if (String(response.status).slice(0, 1) === '4')
      throw new ClientError(response.status, data)
  },
})
