<script setup lang="ts">
import PaymentDialog from '@/views/pages/packages/PaymentDialog.vue'
import TradingAmountDialog from '@/views/pages/packages/TradingAmountDialog.vue'

definePage({
  meta: {
    auth: true,
    middleware: ['payment'],
  },
})
</script>

<template>
  <VRow>
    <VCol
      cols="12"
      class="mx-auto"
    >
      <AppPricing />
    </VCol>
  </VRow>
  <TradingAmountDialog />
  <PaymentDialog />
</template>
