<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { email, helpers, minLength, required, sameAs } from '@vuelidate/validators'
import { RouterLink } from 'vue-router'
import Vue3CountryIntl from 'vue3-country-intl'
import { VForm } from 'vuetify/components/VForm'
import { useCookie } from '../@core/composable/useCookie'
import { router } from '@/plugins/2.router'
import { useAuthStore } from '@/stores/auth'

import AuthProvider from '@/views/pages/authentication/AuthProvider.vue'
import { ValidationError } from '@api/errors'

import authV2RegisterIllustrationBorderedDark from '@images/pages/auth-v2-register-illustration-bordered-dark.png'
import authV2RegisterIllustrationBorderedLight from '@images/pages/auth-v2-register-illustration-bordered-light.png'
import authV2RegisterIllustrationDark from '@images/pages/auth-v2-register-illustration-dark.png'
import authV2RegisterIllustrationLight from '@images/pages/auth-v2-register-illustration-light.png'
import authV2MaskDark from '@images/pages/misc-mask-dark.png'
import authV2MaskLight from '@images/pages/misc-mask-light.png'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import 'vue3-country-intl/lib/vue3-country-intl.css'

const imageVariant = useGenerateImageVariant(authV2RegisterIllustrationLight,
  authV2RegisterIllustrationDark,
  authV2RegisterIllustrationBorderedLight,
  authV2RegisterIllustrationBorderedDark, true)

const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)

const auth = useAuthStore()

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const loading = ref(false)
const accepted = (value: string) => !!value

const state = reactive({
  name: '',
  username: '',
  email: '',
  password: '',
  password_confirmation: '',
  telegram: '',
  signal: '',
  discord: '',
  affiliate_code: useCookie<string>('ref').value ?? '',
  country: '',
  age: '',
  gender: '',
  privacy_policies: false,
})

const rules = {
  name: { required },
  username: { required },
  email: { required, email },
  password: {
    required,
    minLength: minLength(8),
    password: helpers.withMessage('Password must contain at least one uppercase letter, one lowercase letter and one number.', (val: string) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(val)),
  },
  password_confirmation: { required, sameAsPassword: sameAs(computed(() => state.password)) },
  country: { required },
  age: { required },
  gender: { required },
  telegram: {},
  signal: {},
  discord: {},
  affiliate_code: { nullable: helpers.withMessage('', () => true)},
  privacy_policies: { accepted: helpers.withMessage('The Privacy Policy must be accepted.', accepted) },
}

const $externalResults = ref({})

const v$ = useVuelidate(rules, state, { $externalResults })

const isPasswordVisible = ref(false)

const error = ref('')

const register = async () => {
  v$.value.$clearExternalResults()
  await v$.value.$validate()
  if (v$.value.$invalid)
    return

  try {
    loading.value = true
    error.value = ''
    await auth.register(state)
    loading.value = false
    await nextTick(() => {
      router.replace('/login')
    })
  }
  catch (err) {
    if (err instanceof ValidationError) {
      error.value = err.message
      $externalResults.value = err.errors ?? {}
    }
    loading.value = false
  }
}
</script>

<template>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <div class="back-link">
            <RouterLink to="/" class="d-flex gap-x-3 align-center">
              <img src="/icon.png" alt="Tradeasy Home" width="50" height="50">
              <h4 data-v-a23acb87="" class="text-h4 text-capitalize text-truncate font-weight-bold">tradeasy</h4>
            </RouterLink>
          </div>
          <VImg
            max-width="441"
            :src="imageVariant"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <VImg
          class="auth-footer-mask"
          :src="authThemeMask"
        />
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
      style="background-color: rgb(var(--v-theme-surface));"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <VNodeRenderer
            :nodes="themeConfig.app.logo"
            class="mb-6"
          />
          <h5 class="text-h5 mb-1">
            Adventure starts here 🚀
          </h5>
          <p class="mb-0">
            Make your app management easy and fun!
          </p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="register">
            <VScaleTransition>
              <VRow v-if="error">
                <VCol cols="12">
                  <VAlert

                    variant="tonal"
                    color="error"
                  >
                    {{ error }}
                  </VAlert>
                </VCol>
              </VRow>
            </VScaleTransition>
            <VRow>
              <!-- Name -->
              <VCol cols="12">
                <AppTextField
                  v-model="v$.name.$model"
                  :field="v$.name"
                  :disabled="loading"
                  autofocus
                  label="Name*"
                />
              </VCol>

              <!-- Username -->
              <VCol cols="12">
                <AppTextField
                  v-model="v$.username.$model"
                  :field="v$.username"
                  :disabled="loading"
                  label="Username*"
                />
              </VCol>

              <!-- Email -->
              <VCol cols="12">
                <AppTextField
                  v-model="v$.email.$model"
                  :field="v$.email"
                  :disabled="loading"
                  label="Email*"
                  type="email"
                />
              </VCol>

              <!-- Password -->
              <VCol cols="12">
                <AppTextField
                  v-model="v$.password.$model"
                  :field="v$.password"
                  :disabled="loading"
                  label="Password*"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  autocomplete="new-password"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
              </VCol>

              <!-- Password Confirmation -->
              <VCol cols="12">
                <AppTextField
                  v-model="v$.password_confirmation.$model"
                  :field="v$.password_confirmation"
                  :disabled="loading"
                  autocomplete="new-password"
                  label="Confirm Password*"
                  type="password"
                />
              </VCol>

              <!-- Country -->
              <VCol cols="12">
                <VLabel class="mb-1 text-body-2 text-high-emphasis">
                  Country of residence*
                </VLabel>
                <VInput
                  label="Country of residence*"
                  :error-messages="v$.country.$errors[0]?.$message as string"
                  hide-details="auto"
                >
                  <Vue3CountryIntl
                    v-model="v$.country.$model"
                    type="country"
                    :show-area-code="false"
                    :use-chinese="false"
                    placeholder="Select Country"
                    class="input w-100"
                  />
                </VInput>
              </VCol>

              <VCol cols="12">
                <AppSelect
                  v-model="v$.age.$model"
                  label="Age*"
                  :error-messages="v$.age.$errors[0]?.$message as string"
                  :items="['18-25', '25-35', '35-45', '+45']"
                />
              </VCol>
              <VCol cols="12">
                <AppSelect
                  v-model="v$.gender.$model"
                  label="Gender*"
                  :error-messages="v$.gender.$errors[0]?.$message as string"
                  :items="['Male', 'Female']"
                />
              </VCol>

              <VCol cols="6">
                <AppTextField
                  v-model="v$.telegram.$model"
                  :field="v$.telegram"
                  :disabled="loading"
                  label="Telegram"
                  type="text"
                />
              </VCol>
<!--              <VCol cols="6">
                <AppTextField
                  v-model="v$.signal.$model"
                  :field="v$.signal"
                  :disabled="loading"
                  label="Signal"
                  type="text"
                />
              </VCol>-->
<!--              <VCol cols="6">
                <AppTextField
                  v-model="v$.discord.$model"
                  :field="v$.discord"
                  :disabled="loading"
                  label="Discord"
                  type="text"
                />
              </VCol>-->
              <VCol cols="6">
                <AppTextField
                  v-model="v$.affiliate_code.$model"
                  :field="v$.affiliate_code"
                  :disabled="loading"
                  label="Affiliate code"
                  type="text"
                />
              </VCol>
              <!-- password -->
              <VCol cols="12">
                <div class="d-flex align-center mt-2 mb-4">
                  <VCheckbox
                    id="privacy-policy"
                    v-model="v$.privacy_policies.$model"
                    :error="v$.privacy_policies.$error"
                    :error-messages="v$.privacy_policies.$errors[0]?.$message as string"
                    inline
                  >
                    <template #label>
                      <VLabel
                        for="privacy-policy"
                        style="opacity: 1;"
                      >
                        <span class="me-1">I agree to</span>
                        <a
                          href="javascript:void(0)"
                          class="text-primary"
                        >privacy policy & terms</a>
                      </VLabel>
                    </template>
                  </VCheckbox>
                </div>

                <VBtn
                  block
                  type="submit"
                  :loading="loading"
                >
                  Sign up
                </VBtn>
              </VCol>

              <!-- create account -->
              <VCol
                cols="12"
                class="text-center text-base"
              >
                <span>Already have an account?</span>
                <RouterLink
                  class="text-primary ms-2"
                  :to="{ name: 'login' }"
                >
                  Sign in instead
                </RouterLink>
              </VCol>

<!--              <VCol
                cols="12"
                class="d-flex align-center"
              >
                <VDivider />
                <span class="mx-4">or</span>
                <VDivider />
              </VCol>-->

              <!-- auth providers -->
<!--              <VCol
                cols="12"
                class="text-center"
              >
                <AuthProvider />
              </VCol>-->
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";

.v-input--error {
  .vue-country-intl-inputer {
    .country-intl-input {
      border-color: rgb(var(--v-theme-error))
    }
  }
}

.vue-country-intl-inputer {
  background-color: transparent;
  width: 100%;

  .country-intl-label {
    background-color: rgb(var(--v-theme-surface));
  }

  .country-intl-input {
    border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
    background-color: transparent;
  }
}

.back-link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 10px 20px;
}
</style>
