import type { Package } from '@api/models/package'
import type { PackageStoreResponse } from '@api/responses/packages'
import { BaseApiService } from '@api/services/api'

export class PackagesApiService extends BaseApiService {
  constructor() {
    super('packages')
  }

  public async index(): Promise<Package[]> {
    return super.request<Package[]>(super.getUrl())
  }

  public async store() {
    return super.request<PackageStoreResponse>(super.getUrl(), {
      method: 'POST',
    })
  }
}
