<script setup lang="ts">
interface Props {
  icon?: string
  iconSize?: string
  color?: string
}

const props = withDefaults(defineProps<Props>(), {
  icon: 'tabler-x',
  iconSize: '20',
  color: '',
})
</script>

<template>
  <IconBtn
    variant="elevated"
    class="v-dialog-close-btn"
    :color="color"
  >
    <VIcon
      :icon="props.icon"
      :size="props.iconSize"
    />
  </IconBtn>
</template>
