import { FetchError } from 'ofetch'
import type { FetchOptions } from 'ofetch'
import { toast } from 'vuetify-sonner'

interface RequestProps<T> {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  data?: T | null
  retry?: number
}

export class BaseApiService {
  private baseUrl = import.meta.env.VITE_API_BASE_URL || '/api'
  protected resource: string

  constructor(resource: string) {
    this.resource = resource
  }

  public getUrl(path: any = '') {
    if (!path)
      return `${this.baseUrl}${this.resource ? `/${this.resource}` : this.resource}`

    return `${this.baseUrl}${this.resource ? `/${this.resource}` : this.resource}/${path}`
  }

  protected request<R extends object, T>(url: string, { method, data }: RequestProps<R>): Promise<T>

  protected request<T>(url: string): Promise<T>

  protected request<T>(url: string, props: RequestProps<any>): Promise<T>
  protected async request<R extends object, T>(url: string, { method, data, retry }: RequestProps<R> = {
    method: 'GET',
    data: null,
    retry: 0,
  }) {
    const options: FetchOptions<'json'> = {
      retry,
      method,
    }

    if (data) {
      if (method.toLowerCase() === 'get')
        options.query = data
      else
        options.body = data
    }

    try {
      return await $api<T>(url, options)
    }
    catch (e) {
      if (e instanceof FetchError && e.status?.toString().slice(0, 1) === '5')
        toast.error('Something went wrong, please try again later')
      throw e
    }
  }
}
