export const COOKIE_MAX_AGE_1_YEAR = 365 * 24 * 60 * 60

export const PIE_COLORS = [
  '#00A57A',
  '#96FF00',
  '#ff8200',
  '#800080',
  '#055EAD',
  '#FFBF00',
  '#00ffe5',
  '#005bff',
  '#ff1100',
  '#744700',
  '#ff00ff',
  '#00d2ff',
  '#4400FF',
  '#FF00A2',
  '#BBFF00',
  '#FF8800',
  '#00FFE1',
  '#7300FF',
  '#009DFF',
  '#FF00DD',
  '#0000FF',
  '#FFFF00',
  '#00FF00',
  '#000000',
  '#FF64FF',
  '#6464FF',
  '#00FF00',
  '#ff8200',
  '#800080',
  '#4400FF',
  '#FFBF00',
  '#00ffe5',
  '#744700',
  '#ff00ff',
  '#055EAD',
  '#BBFF00',
  '#FF00A2',
  '#00d2ff',
  '#FF00A2',
  '#055EAD',
  '#00A57A',
  '#FF00FF',
  '#ff8200',
  '#800080',
  '#055EAD',
  '#FFBF00',
  '#00ffe5',
  '#005bff',
  '#ff1100',
  '#744700',
  '#ff00ff',
  '#00d2ff',
  '#4400FF',
  '#FF00A2',
  '#BBFF00',
  '#FF8800',
  '#00FFE1',
  '#7300FF',
  '#009DFF',
  '#FF00DD',
  '#0000FF',
  '#FFFF00',
  '#00FF00',
  '#000000',
  '#FF64FF',
  '#6464FF',
  '#00FF00',
  '#ff8200',
  '#800080',
  '#4400FF',
  '#FFBF00',
  '#00ffe5',
  '#744700',
  '#ff00ff',
  '#055EAD',
  '#BBFF00',
  '#FF00A2',
  '#00d2ff',
  '#FF00A2',
  '#055EAD',
]
