import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import type { App } from 'vue'

(window as any).Pusher = Pusher
class TradeasyEcho {
  private readonly echoInstance: Echo
  constructor(options: any) {
    this.echoInstance = new Echo(options)
  }

  install(app: App) {
    app.config.globalProperties.$echo = this.echoInstance
    app.provide('$echo', this.echoInstance)
  }

  public private(channel: string) {
    return this.echoInstance.private(channel)
  }
}

const createEcho = (options: any) => {
  return new TradeasyEcho(options)
}

const echo = createEcho({
  broadcaster: import.meta.env.VITE_BROADCAST_DRIVER ?? 'reverb',
  key: import.meta.env.VITE_REVERB_APP_KEY,
  wsHost: import.meta.env.VITE_REVERB_HOST,
  wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
  wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
  forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
  enabledTransports: ['ws', 'wss'],
  cluster: 'mt1',
})

export { echo }
export default function (app: App) {
  app.use(echo)
}
