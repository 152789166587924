import { StorageSerializers, useStorage } from '@vueuse/core'
import { Auth } from '@api/admin'
import type { Admin } from '@api/models/admin'

export const useAuthStore = defineStore('admin', () => {
  // 👉 Theme
  const admin = useStorage<Admin | null>('adminData', null, undefined, { serializer: StorageSerializers.object })

  const isAuth = computed(() => !!admin.value)
  const login = async (loginData: { email: string; password: string }) => {
    const res = await Auth.login(loginData)

    admin.value = res.admin

    return res
  }

  const getUser = async () => {
    const res = await Auth.user()

    admin.value = res.admin

    return res.status
  }

  const disable2FA = async () => {
    await Auth.disable2FA()
    void getUser()
  }

  const getStatus = async () => {
    return Auth.getStatus()
  }

  return {
    admin,
    isAuth,
    login,
    getUser,
    disable2FA,
    getStatus,
  }
})
// !SECTION

// SECTION Init
/* export const initConfigStore = () => {
  const userPreferredColorScheme = usePreferredColorScheme()
  const vuetifyTheme = useTheme()
  const configStore = useConfigStore()

  watch(
    [() => configStore.theme, userPreferredColorScheme],
    () => {
      vuetifyTheme.global.name.value = configStore.theme === 'system'
        ? userPreferredColorScheme.value === 'dark'
          ? 'dark'
          : 'light'
        : configStore.theme
    })

  onMounted(() => {
    if (configStore.theme === 'system')
      vuetifyTheme.global.name.value = userPreferredColorScheme.value
  })
} */
// !SECTION
