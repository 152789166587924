import { ExchangesApiService } from '@api/services/exchanges'
import { PaymentApiService } from '@api/services/payment'
import { SettingsApiService } from '@api/services/settings'
import { AccountsApiService } from '@api/services/accounts'
import { UserAuthenticationApiService } from '@api/services/auth'
import { PackagesApiService } from '@api/services/packages'

export const Auth = new UserAuthenticationApiService()

export const Packages = new PackagesApiService()

export const Accounts = new AccountsApiService()

export const Settings = new SettingsApiService()

export const Payment = new PaymentApiService()

export const Exchanges = new ExchangesApiService()
