<script setup lang="ts">
import moment from 'moment/moment'
import type { AffiliatePayment } from '@api/models/affiliate'
import { AffiliatePaymentStatus } from '@api/models/affiliate'
import type { LengthAwarePaginatorResponse } from '@api/responses'
import { paginationMeta } from '@api/utils/paginationMeta'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const searchQuery = ref('')
const perPage = ref(50)

const headers = [
  { title: '#', key: 'id' },
  { title: 'User', key: 'name' },
  { title: 'Code', key: 'affiliate_code' },
  { title: 'Amount', key: 'amount' },
  { title: 'Payment Address', key: 'address' },
  { title: 'Requested at', key: 'created_at' },
  { title: 'Paid at', key: 'paid_at' },
  { title: 'Status', key: 'status' },
]

const { data: responseData, execute: fetchPayments, isFetching } = await useApi<LengthAwarePaginatorResponse<AffiliatePayment>>(createUrl('/admin/affiliate/payments/history', {
  query: {
    q: searchQuery,
    perPage,
    page,
    sortBy,
    orderBy,
  },
}))

const paymentsData = computed(() => responseData.value?.data)
const total = computed(() => responseData.value?.total ?? 0)

const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}
</script>

<template>
  <VCard>
    <VCardText class="d-flex flex-wrap py-4 gap-4">
      <div class="me-3 d-flex gap-3">
        <VBtn
          prepend-icon="tabler-refresh"
          :elevation="0"
          @click="fetchPayments"
        >
          Refresh
        </VBtn>
        <AppSelect
          :model-value="perPage"
          :items="[
            { value: 10, title: '10' },
            { value: 25, title: '25' },
            { value: 50, title: '50' },
            { value: 100, title: '100' },
            { value: -1, title: 'All' },
          ]"
          style="inline-size: 6.25rem;"
          @update:model-value="perPage = parseInt($event, 10)"
        />
      </div>
      <VSpacer />

      <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
        <!-- 👉 Search  -->
        <div style="inline-size: 10rem;">
          <AppTextField
            v-model="searchQuery"
            placeholder="Search"
            density="compact"
          />
        </div>

        <!-- 👉 Export button -->
        <VBtn
          variant="tonal"
          color="secondary"
          prepend-icon="tabler-screen-share"
        >
          Export
        </VBtn>
      </div>
    </VCardText>
    <VDivider />
    <VDataTableServer
      v-model:items-per-page="perPage"
      v-model:page="page"
      v-model:sort-by="sort"
      :items="paymentsData"
      :headers="headers"
      :items-length="total"
      return-object
      :loading="isFetching"
      :value-comparator="(bot1, bot2) => bot1.id === bot2.id"
      @update:options="updateOptions"
    >
      <template #item.user_id="{ item }">
        {{ item.user.name }}
      </template>
      <template #item.amount="{ item }">
        {{ formatNumber(item.amount) }}
      </template>
      <template #item.status="{ item }">
        <VChip :color="item.status === AffiliatePaymentStatus.Accepted ? 'success' : 'error'">
          {{ item.status }}
        </VChip>
      </template>
      <template #item.created_at="{ item }">
        {{ moment(item.created_at).format('YYYY-MM-DD HH:mm:ss') }}
      </template>
      <template #item.paid_at="{ item }">
        {{ moment(item.paid_at).format('YYYY-MM-DD HH:mm:ss') }}
      </template>
      <!-- User -->

      <!-- pagination -->
      <template #bottom>
        <VDivider />
        <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
          <p class="text-sm text-disabled mb-0">
            {{ paginationMeta({ page, itemsPerPage: perPage }, total) }}
          </p>

          <VPagination
            v-model="page"
            :length="Math.ceil(total / perPage)"
            :total-visible="$vuetify.display.xs ? 1 : Math.ceil(total / perPage)"
          >
            <template #prev="slotProps">
              <VBtn
                variant="tonal"
                color="default"
                v-bind="slotProps"
                :icon="false"
              >
                Previous
              </VBtn>
            </template>

            <template #next="slotProps">
              <VBtn
                variant="tonal"
                color="default"
                v-bind="slotProps"
                :icon="false"
              >
                Next
              </VBtn>
            </template>
          </VPagination>
        </div>
      </template>
    </VDataTableServer>
  </VCard>
</template>
