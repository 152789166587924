<script setup lang="ts">
import { useAuthStore } from '@stores/admin'
import { useTheme } from 'vuetify'

// import AnalyticsEarningReportsWeeklyOverview from '@/views/admin/dashboard/AnalyticsEarningReportsWeeklyOverview.vue'
// import AnalyticsMonthlyCampaignState from '@/views/admin/dashboard/AnalyticsMonthlyCampaignState.vue'
// import AnalyticsProjectTable from '@/views/admin/dashboard/AnalyticsProjectTable.vue'
// import AnalyticsSalesByCountries from '@/views/admin/dashboard/AnalyticsSalesByCountries.vue'
// import AnalyticsSalesOverview from '@/views/admin/dashboard/AnalyticsSalesOverview.vue'
// import AnalyticsSourceVisits from '@/views/admin/dashboard/AnalyticsSourceVisits.vue'
// import AnalyticsSupportTracker from '@/views/admin/dashboard/AnalyticsSupportTracker.vue'
// import AnalyticsTotalEarning from '@/views/admin/dashboard/AnalyticsTotalEarning.vue'
import AnalyticsWebsiteAnalytics from '@/views/admin/dashboard/AnalyticsWebsiteAnalytics.vue'

// import CardStatisticsVertical from '@core/components/CardStatisticsVertical.vue'
definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const { admin } = useAuthStore()
const vuetifyTheme = useTheme()
const currentTheme = vuetifyTheme.current.value.colors

const statisticsVertical = {
  title: 'Revenue Generated',
  color: 'success',
  icon: 'tabler-credit-card',
  stats: '97.5k',
  height: 120,
  series: [
    {
      data: [300, 350, 330, 380, 340, 400, 380],
    },
  ],
  chartOptions: {
    chart: {
      height: 110,
      type: 'area',
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      enabled: false,
    },
    markers: {
      colors: 'transparent',
      strokeColors: 'transparent',
    },
    grid: {
      show: false,
    },
    colors: [currentTheme.success],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.8,
        opacityFrom: 0.6,
        opacityTo: 0.1,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    xaxis: {
      show: true,
      lines: {
        show: false,
      },
      labels: {
        show: false,
      },
      stroke: {
        width: 0,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      stroke: {
        width: 0,
      },
      show: false,
    },
  },
}
</script>

<template>
  <VRow class="match-height">
    <!-- 👉 Website analytics -->

    <!-- 👉 Sales Overview -->
    <!--
      <VCol
      cols="12"
      md="3"
      sm="6"
      >
      <AnalyticsSalesOverview />
      </VCol>

      &lt;!&ndash; 👉 Statistics Vertical &ndash;&gt;
      <VCol
      cols="12"
      md="3"
      sm="6"
      >
      <CardStatisticsVertical v-bind="statisticsVertical" />
      </VCol>

      &lt;!&ndash; 👉 Earning Reports Weekly Overview &ndash;&gt;
      <VCol
      cols="12"
      md="6"
      >
      <AnalyticsEarningReportsWeeklyOverview />
      </VCol>

      &lt;!&ndash; 👉 Support Tracker &ndash;&gt;
      <VCol
      cols="12"
      md="6"
      >
      <AnalyticsSupportTracker />
      </VCol>

      &lt;!&ndash; 👉 Sales by Countries &ndash;&gt;
      <VCol
      cols="12"
      sm="6"
      lg="4"
      >
      <AnalyticsSalesByCountries />
      </VCol>

      &lt;!&ndash; 👉 Total Earning &ndash;&gt;
      <VCol
      cols="12"
      sm="6"
      lg="4"
      >
      <AnalyticsTotalEarning />
      </VCol>

      &lt;!&ndash; 👉 Monthly Campaign State &ndash;&gt;
      <VCol
      cols="12"
      sm="6"
      lg="4"
      >
      <AnalyticsMonthlyCampaignState />
      </VCol>

      &lt;!&ndash; 👉 Source Visits &ndash;&gt;
      <VCol
      cols="12"
      sm="6"
      lg="4"
      >
      <AnalyticsSourceVisits />
      </VCol>

      &lt;!&ndash; 👉 Project Table &ndash;&gt;
      <VCol
      cols="12"
      lg="8"
      >
      <AnalyticsProjectTable />
      </VCol>
    -->
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/libs/apex-chart.scss";
</style>
