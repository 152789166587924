<script setup lang="ts">
import moment from 'moment'
import type { PaginatedData } from '@api/handlers/pagination'
import type { PackageRequest } from '@api/admin/requests/package'
import type { Package } from '@api/models/package'
import { useCrud } from '@/pages/crud'
import { paginationMeta } from '@/@api/utils/paginationMeta'
import PackageDrawer from '@/views/admin/packages/PackageDrawer.vue'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const searchQuery = ref('')
const mode = ref(null)
const itemsPerPage = ref(50)

const {
  data: responseData,
  execute: fetchPackages,
  isFetching,
} = await useApi<PaginatedData<Package>>(
  createUrl('/admin/packages', {
    query: {
      q: searchQuery,
      mode,
      per_page: itemsPerPage,
      page,
      sortBy,
      orderBy,
    },
  }),
)

const crud = useCrud<Package, PackageRequest>(
  '/admin/packages',
  fetchPackages,
)

const headers = [
  { title: 'Name', key: 'name' },
  { title: 'Market Mode', key: 'mode' },
  { title: 'Fees', key: 'fee' },
  { title: 'Month', key: 'month' },
  { title: 'Reinvestment', key: 'reinvestment_percentage'},
  { title: 'Created', key: 'created_at' },
  { title: 'Actions', key: 'actions', sortable: false },
]

// Update data table options
const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const packagesData = computed<Package[]>(
  () => responseData.value?.data,
)

const total = computed(() => responseData.value?.total ?? 0)
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem"
            @update:model-value="
              itemsPerPage = parseInt($event, 10)
            "
          />
        </div>
        <VSpacer />

        <div class="d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>
          <div style="inline-size: 10rem">
            <VSelect
              v-model="mode"
              label="Market Mode"
              :items="['Bull', 'Bear']"
              clearable
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

          <!-- 👉 Add package button -->
          <VBtn
            prepend-icon="tabler-plus"
            @click="crud.create"
          >
            Add New Package
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="packagesData"
        :headers="headers"
        :items-length="total"
        :loading="isFetching"
        @update:options="updateOptions"
      >
        <template #item.fee="{ item }">
          {{ item.fee }}%
        </template>
        <template #item.reinvestment_percentage="{ item }">
          {{ item.reinvestment_percentage ?? 0 }}%
        </template>
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </template>
        <!-- Package -->

        <!-- Actions -->
        <template #item.actions="{ item }">
          <!--
            <IconBtn @click="deletePackage(item.id)">
            <VIcon icon="tabler-trash" />
            </IconBtn>
          -->

          <IconBtn @click="crud.edit(item)">
            <VIcon icon="tabler-edit" />
          </IconBtn>
          <IconBtn @click="crud.deleteItem(item.id)">
            <VIcon icon="tabler-trash" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{
                paginationMeta(
                  { page, itemsPerPage },
                  total,
                )
              }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(total / itemsPerPage)"
              :total-visible="
                $vuetify.display.xs
                  ? 1
                  : Math.ceil(total / itemsPerPage)
              "
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <PackageDrawer
    v-model:isDrawerOpen="crud.createComponent.value"
    :package="crud.model.value"
    @package-data="crud.drawerUpdate"
  />
  <ConfirmDialog
    v-model="crud.deleteConfirmation.value"
    content="Are you sure you want to delete this package?"
    @confirm="crud.destroy"
  />
  <VDialog
    v-model="crud.loading.value"
    :scrim="false"
    persistent
    width="auto"
  >
    <VCard color="primary">
      <VCardText>
        Please stand by
        <VProgressLinear
          indeterminate
          color="white"
          class="mb-0"
        />
      </VCardText>
    </VCard>
  </VDialog>
</template>
