interface State {
  show: boolean
  content: string | string[]
  confirm: boolean | null
}

export const dialogState = reactive<State>({
  show: false,
  content: '',
  confirm: null,
})

export function useConfirm() {
  const $show = (content: string | string[]): Promise<boolean> => {
    dialogState.content = content
    dialogState.show = true

    return new Promise(resolve => {
      const unwatch = watchEffect(() => {
        if (dialogState.confirm != null) {
          resolve(dialogState.confirm)
          unwatch()
          dialogState.confirm = null
        }
      })
    })
  }

  const $hide = () => {
    dialogState.show = false
  }

  const $cancel = () => {
    dialogState.confirm = false
    dialogState.show = false
  }

  const $confirm = () => {
    dialogState.confirm = true
    dialogState.show = false
  }

  return {
    $show,
    $hide,
    $cancel,
    $confirm,
  }
}
