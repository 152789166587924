<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { router } from '@/plugins/2.router'
import { useAuthStore } from '@stores/auth'
import { ValidationError } from '@api/errors'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import authV2LoginIllustrationBorderedDark from '@images/pages/auth-v2-login-illustration-bordered-dark.png'
import authV2LoginIllustrationBorderedLight from '@images/pages/auth-v2-login-illustration-bordered-light.png'
import authV2LoginIllustrationDark from '@images/pages/auth-v2-login-illustration-dark.png'
import authV2LoginIllustrationLight from '@images/pages/auth-v2-login-illustration-light.png'
import authV2MaskDark from '@images/pages/misc-mask-dark.png'
import authV2MaskLight from '@images/pages/misc-mask-light.png'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'

definePage({
  meta: {
    layout: 'blank',
  },
})

const authStore = useAuthStore()

const authThemeImg = useGenerateImageVariant(
  authV2LoginIllustrationLight,
  authV2LoginIllustrationDark,
  authV2LoginIllustrationBorderedLight,
  authV2LoginIllustrationBorderedDark,
  true)

const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)

const error = ref('')
const $externalResults = ref({})

const state = reactive({
  code: '',
})

const rules = {
  code: { required },
}

const v$ = useVuelidate(rules, state, { $externalResults })

const loading = ref(false)

const onSubmit = async () => {
  v$.value.$clearExternalResults()
  if (!(await v$.value.$validate()))
    return

  loading.value = true
  try {
    await $api('/two-factor-challenge', {
      method: 'POST',
      body: state,
      onResponse: async context => {
        if (context.response.status === 204) {
          await authStore.getUser()
          await router.replace({ name: 'user' })
        }
      },
    })
  }
  catch (e) {
    loading.value = false
    if (e instanceof ValidationError)
      $externalResults.value = e.errors as object
  }
}

const logout = async () => {
  await authStore.logout()
  await router.replace({ name: 'login' })
}

const onInput = () => {
  if (state.code.length >= 6)
    onSubmit()
}
</script>

<template>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <VImg
            max-width="505"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <VImg
          class="auth-footer-mask"
          :src="authThemeMask"
        />
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <VNodeRenderer
            :nodes="themeConfig.app.logo"
            class="mb-6"
          />
          <h4 class="text-h4 mb-1">
            2FA Confirmation
          </h4>
          <p class="mb-0">
            Enter the 2FA code in the field below to continue working
          </p>
        </VCardText>
        <VCardText>
          <VForm @submit.prevent="onSubmit">
            <VScaleTransition>
              <VRow v-if="error">
                <VCol cols="12">
                  <VAlert

                    variant="tonal"
                    color="error"
                  >
                    {{ error }}
                  </VAlert>
                </VCol>
              </VRow>
            </VScaleTransition>
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="v$.code.$model"
                  :field="v$.code"
                  :disabled="loading"
                  autofocus
                  label="2FA Code"
                  @input="onInput"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                  :loading="loading"
                >
                  Submit
                </VBtn>
              </VCol>

              <VCol cols="12">
                <VDivider />
              </VCol>
              <VCol cols="12">
                <a
                  class="text-primary"
                  @click="logout"
                >Log out</a>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style scoped lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
