<script setup lang="ts">
import moment from 'moment'
import { confirmedActionsCreator } from '@/utils/admin/actions'
import DiscountFormDialog from '@/views/admin/affiliate/DiscountFormDialog.vue'
import type { LengthAwarePaginatorResponse } from '@api/responses'
import { paginationMeta } from '@api/utils/paginationMeta'

interface Discount {
  id: number
  code: string
  percentage: number
}
definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const searchQuery = ref('')
const perPage = ref(50)
const exchange = ref()
const user = ref()
const status = ref()

const { data: responseData, execute: fetchDiscounts, isFetching } = await useApi<LengthAwarePaginatorResponse<Discount>>(createUrl('/admin/discounts', {
  query: {
    q: searchQuery,
    perPage,
    page,
    sortBy,
    orderBy,
    exchange,
    user,
    status,
  },
}))

const createConfirmAction = confirmedActionsCreator(fetchDiscounts)

const headers = [
  { title: 'user', key: 'user_id' },
  { title: 'Code', key: 'code' },
  { title: 'Percentage', key: 'percentage' },
  { title: 'Max Month', key: 'duration' },
  { title: 'Expiry Date', key: 'expired_at' },
  { title: 'Actions', key: 'actions' },
]

// Update data table options
const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const discounts = computed(() => responseData.value?.data)
const total = computed(() => responseData.value?.total ?? 0)
const discount = ref()
const discountFormDialogOpen = ref(false)

const createDiscount = () => {
  discount.value = undefined
  discountFormDialogOpen.value = true
}

const editDiscount = (_discount: Discount) => {
  discount.value = _discount
  discountFormDialogOpen.value = true
}

const deleteDiscount = createConfirmAction('Are you sure you want to Delete this discount?', (_bot: Discount) => $api(`/admin/discounts/${_bot.id}`, {
  method: 'DELETE',
}))
</script>

<template>
  <section>
    <VCard :loading="isFetching">
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <VBtn
            prepend-icon="tabler-refresh"
            :elevation="0"
            @click="fetchDiscounts"
          >
            Refresh
          </VBtn>
          <AppSelect
            :model-value="perPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="perPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-bot-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>
          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>
          <!-- 👉 Add user button -->
          <VBtn
            prepend-icon="tabler-plus"
            @click="createDiscount"
          >
            Create New Discount
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <VDataTableServer
        v-model:items-per-page="perPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="discounts"
        :headers="headers"
        :items-length="total"
        show-select
        return-object
        :loading="isFetching"
        :value-comparator="(bot1, bot2) => bot1.id === bot2.id"
        @update:options="updateOptions"
      >
       <template #item.user_id="{ item }">
         {{ item.user.name }}
       </template>
        <template #item.percentage="{ item }">
          {{ item.percentage }}%
        </template>
        <!-- Actions -->
        <template #item.expired_at="{ item }">
          <template v-if="item.expired_at">
            {{ moment(item.expired_at).format('YYYY-MM-DD') }}
          </template>
        </template>

        <template #item.actions="{ item }">
          <div class="d-flex items-center">
            <VTooltip text="Sync Bot">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="blue-darken-1"
                  @click="editDiscount(item)"
                >
                  <VIcon icon="tabler-edit" />
                </IconBtn>
              </template>
            </VTooltip>
            <VTooltip text="Delete Discount">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="error"
                  @click="deleteDiscount(item)"
                >
                  <VIcon icon="tabler-trash" />
                </IconBtn>
              </template>
            </VTooltip>
          </div>
        </template>

        <!-- pagination -->

        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage: perPage }, total) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(total / perPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(total / perPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <DiscountFormDialog
    v-model="discountFormDialogOpen"
    :discount="discount"
    @success="fetchDiscounts"
  />
</template>
