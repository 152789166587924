import { Accounts, Exchanges } from '@api'
import type { Account } from '@api/models/account'
import type { Exchange } from '@api/models/exchange'
import { usePaymentStore } from '@stores/payment'

enum Windows {
  Connect,
  Home,
}
export const useAccountsStore = defineStore('accounts', () => {
  const exchanges = ref<Exchange[]>([])
  const accounts = ref<Account[]>([])
  const exchange = ref<Exchange | undefined>()
  const account = ref<Account | undefined>()
  const activeWindow = ref<Windows | undefined>()
  const activeAccount = ref()
  const loading = ref(true)

  const getExchanges = async () => {
    exchanges.value = await Exchanges.index()
  }

  const getAccounts = async () => {
    accounts.value = await Accounts.index()
  }

  const checkWindow = () => {
    if (accounts.value.length < 1) {
      activeWindow.value = Windows.Connect
    }
    else {
      if (accounts.value.length < 2)
        account.value = accounts.value[0]

      activeWindow.value = Windows.Home
    }
  }

  const $init = async () => {
    // activeAccount.value = null
    // account.value = undefined
    await Promise.all([getExchanges(), getAccounts()])
    checkWindow()
    loading.value = false
  }

  const addAccount = async (accountId: number) => {
    loading.value = true
    await Promise.all([getExchanges(), getAccounts()])
    activeAccount.value = accountId
    account.value = accounts.value.find(acc => acc.id === accountId)
    checkWindow()
    loading.value = false
  }

  const updateAccount = async (accountId: number, data: any) => {
    await Accounts.update(accountId, data)
    await Promise.all([getExchanges(), getAccounts()])
    account.value = accounts.value.find(acc => acc.id === accountId)
  }

  const refreshAccount = async (accountId: number) => {
    const res = await $api(`/accounts/${accountId}/show`)
    if (account.value && account.value.id === res.id)
      account.value = res

    const index = accounts.value.findIndex(_account => _account.id === accountId)
    const accs = accounts.value

    accs[index] = res
    accounts.value = accs
  }

  watch(account, () => {
    const paymentStore = usePaymentStore()

    paymentStore.amount = undefined
  })

  const $reset = () => {
    exchanges.value = []
    accounts.value = []
    exchange.value = undefined
    account.value = undefined
    activeWindow.value = undefined
    activeAccount.value = undefined
    loading.value = true
  }

  return {
    exchanges,
    accounts,
    exchange,
    account,
    activeWindow,
    activeAccount,
    loading,
    getAccounts,
    getExchanges,
    refreshAccount,
    addAccount,
    updateAccount,
    $init,
    $reset,
  }
})
