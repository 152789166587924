<script lang="ts" setup>
import AccountSettingsAccount from '@/views/pages/account/AccountSettingsAccount.vue'
import AccountSettingsSecurity from '@/views/pages/account/AccountSettingsSecurity.vue'

const route = useRoute('account-tab')

const activeTab = computed({
  get: () => route.params.tab,
  set: () => route.params.tab,
})

// tabs
const tabs = [
  { title: 'Account', icon: 'tabler-users', tab: 'account' },
  { title: 'Security', icon: 'tabler-lock', tab: 'security' },
  { title: 'Affiliate', icon: 'tabler-affiliate', tab: 'affiliate' },
]
definePage({
  meta: {
    navActiveLink: 'account-tab',
    auth: true,
    verified: true,
  },
})
</script>

<template>
  <div>
    <VTabs
      v-model="activeTab"
      class="v-tabs-pill"
    >
      <VTab
        v-for="item in tabs"
        :key="item.icon"
        :value="item.tab"
        :to="{ name: 'account-tab', params: { tab: item.tab } }"
      >
        <VIcon
          size="20"
          start
          :icon="item.icon"
        />
        {{ item.title }}
      </VTab>
    </VTabs>

    <VWindow
      v-model="activeTab"
      class="mt-6 disable-tab-transition"
      :touch="false"
    >
      <!-- Account -->
      <VWindowItem value="account">
        <AccountSettingsAccount />
      </VWindowItem>

      <!-- Security -->
      <VWindowItem value="security">
        <AccountSettingsSecurity />
      </VWindowItem>

      <VWindowItem value="affiliate">
        <AccountSettingsSecurity />
      </VWindowItem>
    </VWindow>
  </div>
</template>
