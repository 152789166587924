import type { Exchange } from '@api/models/exchange'
import { BaseApiService } from '@api/services/api'

export class ExchangesApiService extends BaseApiService {
  constructor() {
    super('exchanges')
  }

  public index() {
    return super.request<Exchange[]>(super.getUrl())
  }
}
