<script setup lang="ts">
import { dialogState } from '@/composables/confirm'

const state = dialogState

const confirm = useConfirm()
</script>

<template>
  <!-- 👉 Confirm Dialog -->
  <VDialog
    :max-width="Array.isArray(state.content) ? 700 : 500"
    :model-value="state.show"
  >
    <VCard class="text-center px-10 py-6">
      <VCardText>
        <VBtn
          icon
          variant="outlined"
          color="warning"
          class="my-4"
          style=" block-size: 88px;inline-size: 88px; pointer-events: none;"
        >
          <span class="text-5xl">!</span>
        </VBtn>


        <template v-if="Array.isArray(state.content)">
          <h6
            v-for="message in state.content"
            class="text-lg font-weight-medium"
          >
            {{ message }}
          </h6>
        </template>
        <h6
          v-else
          class="text-lg font-weight-medium"
        >
          {{ state.content }}
        </h6>
      </VCardText>

      <VCardText class="d-flex align-center justify-center gap-2">
        <VBtn
          variant="elevated"
          @click="confirm.$confirm()"
        >
          Confirm
        </VBtn>

        <VBtn
          color="secondary"
          variant="tonal"
          @click="confirm.$cancel()"
        >
          Cancel
        </VBtn>
      </VCardText>
    </VCard>
  </VDialog>
</template>
