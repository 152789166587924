<script setup lang="ts">
import AccountSettingsSecurity from '@/views/admin/account/AccountSettingsSecurity.vue'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})
</script>

<template>
  <AccountSettingsSecurity />
</template>

<style scoped lang="scss">

</style>
