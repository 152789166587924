interface State {
  show: boolean
  content: string
  confirm: boolean | null
}

export const dialogState = reactive<State>({
  show: false,
  content: '',
  confirm: null,
})

export function useLoading() {
  const isLoading = computed(() => dialogState.show)
  const $show = () => {
    dialogState.show = true
  }

  const $hide = () => {
    dialogState.show = false
  }

  return {
    $show,
    $hide,
    isLoading,
  }
}
