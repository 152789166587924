import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import { router } from '@/plugins/2.router'

const browserTracing = new Sentry.BrowserTracing({
  routingInstrumentation: Sentry.vueRouterInstrumentation(router),
})

const replay = new Sentry.Replay({
  maskAllText: false,
  blockAllMedia: false,
})

export const initSentry = (app: App) => Sentry.init({
  dsn: 'https://7dfd9d45da5ed39a014951c314ddb2de@o1030249.ingest.sentry.io/4506471289192448',
  app,
  integrations: [
    browserTracing,
    replay,
  ],

  tracePropagationTargets: ['localhost', /^https:\/\/better\.tradeasy\.app\/api/],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
