import { useStorage } from '@vueuse/core'
import { useAuthStore } from '@stores/auth'
import { usePaymentStore } from '@/stores/payment'

/**
 * Return if a user is logged in
 * This is completely up to you and how you want to store the token in your frontend application,
 * e.g., If you are using cookies to store the application, please update this function
 */

export const isAdminLoggedIn = () =>
  !!useStorage('adminData', {}).value

export const paymentHasAccount = () => {
  if (!usePaymentStore().accountId)
    return { name: 'user' }
}

export const twoFa = () => {
  console.log(useAuthStore())
}
