import type { MessageResponse } from '@api/models'
import type { Account } from '@api/models/account'
import type { UpdateAccountRequest } from '@api/requests/accounts'
import { BaseApiService } from '@api/services/api'

export class AccountsApiService extends BaseApiService {
  constructor() {
    super('accounts')
  }

  public index() {
    return super.request<Account[]>(super.getUrl())
  }

  public async update(accountId: number, data: object) {
    return super.request<object, MessageResponse>(super.getUrl(accountId), {
      method: 'PUT',
      data,
    })
  }
}
