<script setup lang="ts">
import { router } from '@/plugins/2.router'
import { useAuthStore } from '@/stores/auth'

definePage({
  path: '/email/verify/:id/:hash',
  meta: {
    layout: 'blank',
  },
})

const authStore = useAuthStore()

onMounted(async () => {
  await authStore.getUser()
  await nextTick(() => {
    router.replace({ name: 'user' })
  })
})
</script>
