import type { Package } from '@api/models/package'
import type { PackageRequest } from '@api/admin/requests/package'
import type { MessageResponse } from '@api/models'
import type { LengthAwarePaginatorResponse } from '@api/responses'
import { BaseApiService } from '@api/services/api'

export class PackagesApiService extends BaseApiService {
  constructor() {
    super('admin/packages')
  }

  public index() {
    return super.request<LengthAwarePaginatorResponse<Package>>(super.getUrl())
  }

  public store(data: PackageRequest) {
    return super.request<PackageRequest, MessageResponse>(super.getUrl(), {
      method: 'POST',
      data,
    })
  }

  public update(id: number, data: PackageRequest) {
    return super.request<PackageRequest, MessageResponse>(super.getUrl(id), {
      method: 'PUT',
      data,
    })
  }

  public destroy(id: number) {
    return super.request<MessageResponse>(super.getUrl(id), {
      method: 'DELETE',
    })
  }
}
