interface State {
  show: boolean
  content: string | string[]
  confirm: boolean | null
}

export const errorDialogState = reactive<State>({
  show: false,
  content: '',
  confirm: null,
})

export function useError() {
  const $show = (content: string | string[]): void => {
    errorDialogState.content = content
    errorDialogState.show = true
  }

  const $hide = () => {
    errorDialogState.show = false
    errorDialogState.content = ''
  }

  return {
    $show,
    $hide,
  }
}
