import { StatusResponse } from '@api/admin/responses/auth'
import { ValidationError } from '@api/errors'
import type { MessageResponse } from '@api/models'
import type { LoginResponse, RegisterResponse, UpdateProfileResponse } from '@api/models/auth'
import type { LoginRequest, RegisterRequest, ResetPasswordRequest, VerifyEmailRequest } from '@api/requests/auth'
import type { UserResponse } from '@api/responses/auth'
import { BaseApiService } from '@api/services/api'

export class AuthenticationApiService extends BaseApiService {
  constructor(namespace: string = '') {
    super(`${namespace ? `${namespace}/` : ''}auth`)
  }

  protected makeRequest<T>(url: string, data: object | null = {}, method = 'POST') {
    return $api<T>(url, {
      method,
      body: data,
      onResponseError({ response }) {
        if (response.status === 422)
          throw new ValidationError(response._data)
      },
    })
  }

  public async loginRequest<T>(loginData: LoginRequest) {
    await this.makeRequest('/sanctum/csrf-token', null, 'GET')

    return this.makeRequest<T>(this.getUrl('login'), loginData)
  }

  public async logout() {
    return this.makeRequest<MessageResponse>(this.getUrl('logout'), null, 'GET')
  }

  public async forgotPassword(data: { email: string }) {
    return this.makeRequest<MessageResponse>(this.getUrl('forgot-password'), data)
  }

  public async resetPassword(data: ResetPasswordRequest) {
    return this.makeRequest<MessageResponse>(this.getUrl('reset-password'), data)
  }
}

export class UserAuthenticationApiService extends AuthenticationApiService {
  public async register(registerData: RegisterRequest) {
    return this.makeRequest<RegisterResponse>(this.getUrl('register'), registerData)
  }

  public async verifyEmail(verifyEmailData: VerifyEmailRequest) {
    return this.makeRequest<UserResponse>(this.getUrl('email/verify'), verifyEmailData)
  }

  public async resetEmailChange() {
    return this.makeRequest<UserResponse>('email/reset')
  }

  public async resendVerificationEmail() {
    return this.makeRequest<MessageResponse>(this.getUrl('email/resend'))
  }

  public async user() {
    return this.makeRequest<UserResponse>(this.getUrl('user'), null, 'GET')
  }

  public async loginRequest<T>(loginData: LoginRequest) {
    await this.makeRequest('/sanctum/csrf-token', null, 'GET')

    return this.makeRequest<T>('login', loginData)
  }

  public async login(loginData: LoginRequest) {
    return this.loginRequest<LoginResponse>(loginData)
  }

  public async updateProfile(profileData: FormData) {
    return super.makeRequest<UpdateProfileResponse>('/profile', profileData, 'POST')
  }

  public async resetProfile(){
    return super.makeRequest<UpdateProfileResponse>('/profile/reset', {}, 'POST')
  }

  public async enable2FA() {
    return super.makeRequest('/user/two-factor-authentication', null, 'POST')
  }

  public async disable2FA() {
    return super.makeRequest('/user/two-factor-authentication', null, 'DELETE')
  }

  public async getStatus() {
    return this.makeRequest<StatusResponse>('/user/status', null, 'GET')
  }
}
