export class ClientError extends Error {
  public readonly response: any

  constructor(public readonly status: number, res: any) {
    super(res.message)
    this.name = 'ClientError'
    this.response = res
  }
}

export class ValidationError extends Error {
  public readonly message: any
  public readonly errors?: object
  constructor(res: { message: any; errors?: object }) {
    super()
    this.message = res.message
    this.name = 'ValidationError'
    this.errors = res.errors
  }
}
export interface DiscountValidationError {
  code: string[]
}

export class AccountDeletionError extends ValidationError {
  public readonly remaining: any
  constructor(res: { message: any; errors?: object; remaining: any }) {
    super(res)
    this.remaining = res.remaining
  }
}
