type barColor = 'success' | 'error' | null

interface State {
  show: boolean
  content: string
  color: barColor
}

export const snackBarState = reactive<State>({
  show: false,
  bars: [],
  content: '',
  color: null,
})

export function useSnackbar() {

  const $show = (content: string) => {
    snackBarState.content = content
    snackBarState.show = true
  }

  const $error = (content: string) => {
    snackBarState.color = 'error'
    $show(content)
  }

  const $success = (content: string) => {
    snackBarState.color = 'success'
    $show(content)
  }

  return {
    $error,
    $success,
    $show,
  }
}
