import { PIE_COLORS } from '@/utils/constants'
import { LoadingState } from '@stores/store.interface'

const initState = {
  loading: false,
  balanceLoading: false,
  dealsLoading: false,
  balance: {
    usd: 0,
    btc: 0,
  },
  deals: {
    active: 0,
    count: 0,
    profit: 0,
  },
  stats: {
    account: {
      usd: 0,
      btc: 0,
    },
    bots: {
      today: 0,
      week: 0,
      month: 0,
      overall: 0,
      last_30_days: 0,
      overallTradingAmount: 0,
    },
    deals: {
      active: 0,
      count: 0,
      profit: 0,
    },
    charts: {
      last_30_days: {
      },
      last_12_months: {
      },
    },
  },
  pie: {
    data: null,
    labels: null,
  },
}

export const useHomeStore = defineStore('home', () => {
  const state = ref(LoadingState.Initial)
  const loading = ref(initState.loading)
  const balanceLoading = ref(initState.balanceLoading)
  const dealsLoading = ref(initState.dealsLoading)

  const balance = ref(initState.balance)

  const deals = ref(initState.deals)

  const stats = ref(initState.stats)

  const pie = ref(initState.pie)

  const $reset = () => {
    state.value = LoadingState.Initial
    loading.value = initState.loading
    balanceLoading.value = initState.balanceLoading
    dealsLoading.value = initState.dealsLoading
    balance.value = initState.balance
    deals.value = initState.deals
    stats.value = initState.stats
    pie.value = initState.pie
  }

  const getStats = async () => {
    loading.value = true
    stats.value = await $api('home/stats')
    loading.value = false
  }

  const getBalance = async () => {
    balanceLoading.value = true
    balance.value = await $api('home/balance')
    balanceLoading.value = false
  }

  const getDeals = async () => {
    dealsLoading.value = true
    deals.value = await $api('home/deals')
    dealsLoading.value = false
  }

  const getPie = async () => {
    pie.value = await $api('home/pie')
  }

  const pieData = computed(() => ({
    datasets: [
      {
        data: pie.value.data,
        backgroundColor: PIE_COLORS,
      },
    ],
    labels: pie.value.labels,
  }))

  const yearChartData = computed(() => ({
    datasets: [
      {
        label: '',
        type: 'line',
        data: stats.value.charts.last_12_months,
        backgroundColor: 'orange',
        borderColor: 'orange',
        fill: false,
        order: 1,
      },
      {
        label: '',
        data: stats.value.charts.last_12_months,
        backgroundColor: (context: any) => {
          if (context.raw) {
            if (context.raw.y > 0)
              return 'green'

            else
              return 'red'
          }
        },
        fill: true,
        tension: 0.25,
        order: 2,
      },
    ],
  }))

  const $init = (force = false) => {
    if (state.value !== LoadingState.Initial && !force)
      return
    state.value = LoadingState.Loading
    void getBalance()
    void getDeals()
    void getStats()
    void getPie()
    state.value = LoadingState.Loaded
  }

  return {
    loading,
    balanceLoading,
    dealsLoading,
    balance,
    deals,
    stats,
    pie,
    yearChartData,
    pieData,
    $init,
    $reset,
  }
})
