import type { Settings } from '@api/models/settings'
import { BaseApiService } from '@api/services/api'

export class SettingsApiService extends BaseApiService {
  constructor() {
    super('settings')
  }

  public async index() {
    return super.request<Settings>(super.getUrl())
  }
}
