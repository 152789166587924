<script setup lang="ts">
import ConnectExchangeDrawer from '@/views/pages/ConnectExchangeDrawer.vue'
import ExchangesHome from '@/views/pages/index/ExchangesHome.vue'
import ExchangeShow from '@/views/pages/index/ExchangeShow.vue'
import NoExchangeKeys from '@/views/pages/index/NoExchangeKeys.vue'
import { Accounts, Exchanges } from '@api'
import type { Exchange } from '@api/models/exchange'
import { useAccountsStore } from '@stores/accounts'

enum Windows {
  Connect,
  Home,
}

definePage({
  meta: {
    auth: true,
    verified: true,
    // middleware: ['twoFa'],
  },
})

const accountsStore = useAccountsStore()
const { exchanges, accounts, exchange, account, activeWindow, activeAccount, loading } = storeToRefs(accountsStore)
const isDrawerOpen = ref(false)

const connectExchange = (_exchange: Exchange) => {
  isDrawerOpen.value = true
  exchange.value = _exchange
}

const setIsDrawerOpen = (val: boolean) => {
  isDrawerOpen.value = val
}

const getExchanges = async () => {
  exchanges.value = await Exchanges.index()
}

const getAccounts = async () => {
  accounts.value = await Accounts.index()
}

const refresh = async (spinner: boolean = false) => {
  if (spinner)
    loading.value = true

  await Promise.all([getExchanges(), getAccounts()])
  if (spinner)
    loading.value = false
}

const goConnect = () => {
  activeWindow.value = Windows.Connect
  activeAccount.value = null
}

const refreshAccount = async (accountId: number) => {
  const res = await $api(`/accounts/${accountId}/show`)
  if (account.value && account.value.id === res.id)
    account.value = res

  const index = accounts.value.findIndex(_account => _account.id === accountId)
  const accs = accounts.value

  accs[index] = res
  accounts.value = accs
}

onMounted(() => {
  accountsStore.$init()
})
</script>

<template>
  <VOverlay
    :model-value="loading"
    persistent
    class="align-center justify-center"
  >
    <VProgressCircular
      color="primary"
      indeterminate
      size="64"
    />
  </VOverlay>
  <VFadeTransition>
    <VWindow
      v-if="!loading"
      v-model="activeWindow"
      :touch="false"
    >
      <VWindowItem
        v-if="exchanges.length"
        :value="Windows.Connect"
      >
        <NoExchangeKeys
          :exchanges="exchanges"
          @connect="connectExchange($event)"
        />
      </VWindowItem>
      <VWindowItem :value="Windows.Home">
        <ExchangeShow
          v-if="account"
          :key="account.id"
          :account="account"
          @update="refreshAccount"
          @disconnected="goConnect"
        />
        <ExchangesHome v-show="!account" />
      </VWindowItem>
    </VWindow>
  </VFadeTransition>
  <ConnectExchangeDrawer
    :is-drawer-open="isDrawerOpen"
    :exchange="exchange"
    @update:is-drawer-open="setIsDrawerOpen"
    @refresh="refresh"
  />
</template>

<style>
/*noinspection CssUnusedSymbol*/
.v-theme--light {
  --tab-active-color: black;
}
</style>

<style scoped lang="scss">
.add-btn {
  background-color: transparent !important;
  color: rgb(var(--v-theme-primary)) !important;
  transition: .3s all ease !important;
  overflow: hidden !important;
  border-radius: 8px !important;

  .btn-text {
    display: none;
  }
&.active {
  background-color: rgb(var(--v-theme-primary)) !important;
  color: rgb(var(--v-theme-on-primary)) !important;
  width: 180px !important;

  .btn-text {
    display: inline;
  }
}
  &:hover {
    background-color: rgb(var(--v-theme-primary)) !important;
    color: rgb(var(--v-theme-on-primary)) !important;
    width: 180px !important;

    .btn-text {
      display: inline;
    }
  }
}

.exchange-btn {
  &:hover {

    background-color: rgb(var(--v-theme-perfect-scrollbar-thumb)) !important;
    color: rgb(var(--tab-active-color)) !important;
    &:not(.v-tab--selected){
      border-radius: 8px !important;
    }
  }

  &.v-tab--selected {
    background-color: rgb(var(--v-theme-perfect-scrollbar-thumb)) !important;
    transition: none;
    color: rgb(var(--tab-active-color)) !important;
    /*color: white !important;*/

    &:before {
      transition: none !important;
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-width: 5px;
      border-radius: 17px;
      background: transparent;
      border-style: solid;
      border-color: transparent rgb(var(--v-theme-perfect-scrollbar-thumb)) transparent transparent;
      rotate: 140deg;
      right: -25px;
      bottom: -5px;
    }

    &:after {
      transition: none !important;
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-width: 5px;
      border-radius: 17px;
      background: transparent;
      border-style: solid;
      border-color: transparent rgb(var(--v-theme-perfect-scrollbar-thumb)) transparent transparent;
      rotate: 46deg;
      bottom: -5px;
      left: -25px;
      top: unset !important;
      opacity: 1 !important;
    }
  }

}
</style>
