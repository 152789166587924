import type { LoginResponse, StatusResponse } from '@api/admin/responses/auth'
import type { LoginRequest } from '@api/requests/auth'
import { AuthenticationApiService } from '@api/services/auth'

export class AdminAuthenticationApiService extends AuthenticationApiService {
  constructor() {
    super('admin')
  }

  public async user(): Promise<LoginResponse> {
    return super.makeRequest<LoginResponse>('/api/admin/user', null, 'GET')
  }

  public async disable2FA() {
    return super.makeRequest('/api/admin/two-factor-authentication', null, 'DELETE')
  }

  public async login(loginData: LoginRequest): Promise<LoginResponse> {
    return super.loginRequest<LoginResponse>(loginData)
  }

  public async getStatus() {
    return this.makeRequest<StatusResponse>('/api/admin/status', null, 'GET')
  }
}
