<script setup lang="ts">
import moment from 'moment'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const route = useRoute()
const logs = ref([])

onMounted(async () => {
  logs.value = await $api(`/api/admin/admins/${route.params.id}/logs`)
})

const time = (value: string) => moment(value).format('YYYY-MMM-DD hh:mm:ss A')
</script>

<template>
  <VCard title="Activity Logs" />
  <VList>
    <VListItem
      v-for="log in logs"
      :key="log.id"
      :title="log.title"
      :subtitle="time(log.created_at)"
    >
      <template #append>
        <VBtn
          variant="text"
          color="default"
          icon="tabler-info-circle"
        />
      </template>
    </VListItem>
  </VList>
</template>

<style scoped lang="scss">

</style>
