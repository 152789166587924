<script setup lang="ts">
import { snackBarState } from '@/composables/snackbar'
import { checkForLocale } from '@/plugins/i18n'
import ScrollToTop from '@core/components/ScrollToTop.vue'
import initCore from '@core/initCore'
import { initConfigStore, useConfigStore } from '@core/stores/config'
import { hexToRgb } from '@layouts/utils'
import { useTheme } from 'vuetify'
import { VSonner } from 'vuetify-sonner'

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
initCore()
initConfigStore()

const configStore = useConfigStore()

const { locale } = useI18n()

watch(locale, newLocale => {
  checkForLocale(newLocale)
})
</script>

<template>
  <VLocaleProvider :rtl="configStore.isAppRTL">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <ConfirmDialog />
      <LoadingDialog />
      <ErrorDialog />
      <VSonner position="bottom-left" />
      <VSnackbar v-model="snackBarState.show" :timeout="2000" :color="snackBarState.color" transition="fade-transition"
        location="top end">
        {{ snackBarState.content }}
      </VSnackbar>
      <ScrollToTop />
    </VApp>
  </VLocaleProvider>
</template>
