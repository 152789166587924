import type { PaymentStoreResponse } from '@api/responses/payment'

export class PaymentDetails {
  priceAmount: number
  priceCurrency: string
  payAddress: string
  payAmount: number
  payCurrency: string
  invoiceId: number

  constructor(obj: PaymentStoreResponse) {
    this.invoiceId = obj.invoice_id
    this.payAddress = obj.pay_address
    this.payAmount = obj.pay_amount
    this.payCurrency = obj.pay_currency
    this.priceAmount = obj.price_amount
    this.priceCurrency = obj.price_currency
  }
}
