<script setup lang="ts">
definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const loading = ref(false)

const settings = ref({
  bot_mode: 'FULL',
  market_mode: 'Bull',
  min_trading_amount: 1000,
  max_trading_amount: 5000,
  min_affiliate_withdrawal: 1000,
  min_increase_pool: 1000,
  trading_percentage: 5,
})

const getSettings = async () => {
  loading.value = true

  settings.value = await $api('/admin/settings')

  loading.value = false
}

const onSubmit = async () => {
  loading.value = true
  await $api('/admin/settings', {
    method: 'POST',
    body: settings.value,
  })

  loading.value = false
}

onMounted(() => {
  getSettings()
})
</script>

<template>
  <form @submit.prevent="onSubmit">
    <VCard
      title="Settings"
      :loading="loading"
    >
      <VCardText>
        <VContainer>
          <VRow>
            <VCol>
              <VRadioGroup
                v-model="settings.bot_mode"
                label="Bot Mode"
              >
                <VRadio
                  value="FULL"
                  label="FULL"
                />
                <VRadio
                  value="DCA"
                  label="DCA"
                />
              </VRadioGroup>
            </VCol>
            <VCol>
              <VRadioGroup
                v-model="settings.market_mode"
                label="Market Mode"
              >
                <VRadio
                  value="Bull"
                  label="Bull"
                />
                <VRadio
                  value="Bear"
                  label="Bear"
                />
              </VRadioGroup>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VLabel class="mb-2">
                TRADING LIMIT IN $
              </VLabel>
              <VRow>
                <VCol>
                  <VTextField
                    v-model.number="settings.min_trading_amount"
                    label="Min"
                  />
                </VCol>
                <VCol>
                  <VTextField
                    v-model="settings.max_trading_amount"
                    label="Max"
                  />
                </VCol>
              </VRow>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VLabel class="mb-2">
                Affiliates Withdrawal Limit in $
              </VLabel>
              <VRow>
                <VCol>
                  <VTextField
                    v-model.number="settings.min_affiliate_withdrawal"
                    label="Min"
                  />
                </VCol>
              </VRow>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VLabel class="mb-2">
                Increase Trading Pool Limit in $
              </VLabel>
              <VRow>
                <VCol>
                  <VTextField
                    v-model.number="settings.min_increase_pool"
                    label="Min"
                  />
                </VCol>
              </VRow>
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <VBtn type="submit">
          Submit
        </VBtn>
      </VCardActions>
    </VCard>
  </form>
</template>
