<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required, requiredUnless } from '@vuelidate/validators'
import NotificationTemplateFormDialog from '@/views/admin/notification/NotificationTemplateFormDialog.vue'
import NotificationTemplatesDialog from '@/views/admin/notification/NotificationTemplatesDialog.vue'
import { ValidationError } from '@api/errors'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const users = ref([])
const loading = ref(false)

const state = reactive({
  users: [],
  title: '',
  description: '',
})

provide('state', state)

const rules = {
  users: { required },
  title: { requiredUnless: requiredUnless(computed(() => !!state.description)) },
  description: { requiredUnless: requiredUnless(computed(() => !!state.title)) },
}

const $externalResults = ref({})
const v$ = useVuelidate(rules, state, {
  $externalResults,
  $scope: false,
})

const getUsers = async () => {
  users.value = await $api('/admin/users?nopaginate')
}

const submit = async () => {
  v$.value.$clearExternalResults()
  if (!(await v$.value.$validate()))
    return

  try {
    loading.value = true
    await $api('/admin/notification', {
      method: 'POST',
      body: state,
    })
    state.users = []
    state.title = ''
    state.description = ''
    v$.value.$clearExternalResults()
    v$.value.$reset()
  }
  catch (e) {
    if (e instanceof ValidationError)
      $externalResults.value = e.errors as object
  }
  finally {
    loading.value = false
  }
}

provide('sendNotification', submit)

const notificationTemplatesDialogOpen = ref(false)
const notificationTemplateFormDialogOpen = ref(false)
const send = ref(false)


const saveTemplate = async (_send = false) => {
  v$.value.$reset()
  let valid: boolean
  if (_send) {
    valid = await v$.value.$validate()
  }
  else {
    const titleValid = await v$.value.title.$validate()
    const descValid = await v$.value.description.$validate()

    valid = titleValid && descValid
  }
  if (!valid)
    return

  notificationTemplateFormDialogOpen.value = true
  send.value = _send
}

getUsers()

const useTemplate = temp => {
  state.title = temp.title
  state.description = temp.description
}
</script>

<template>
  <VCard>
    <VCardItem>
      <VCardTitle>Send Notification to Users</VCardTitle>
      <template #append>
        <VBtn @click="notificationTemplatesDialogOpen = true">
          Notification Templates
        </VBtn>
      </template>
    </VCardItem>
    <VOverlay
      contained
      :model-value="loading"
      class="d-flex justify-center"
    >
      <VProgressCircular
        indeterminate
        size="x-large"
      />
    </VOverlay>
    <VForm @submit.prevent="submit">
      <VCardText>
        <VRow>
          <VCol>
            <AppSelect
              v-model="v$.users.$model"
              label="Users"
              multiple
              :items="users"
              item-value="id"
              item-title="name"
              :error-messages="v$.users.$errors[0]?.$message as string"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <AppTextField
              v-model="v$.title.$model"
              label="Title"
              :field="v$.title"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <AppTextarea
              v-model="v$.description.$model"
              label="Description"
              :field="v$.description"
            />
          </VCol>
        </VRow>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <VBtn
          type="button"
          @click="saveTemplate(false)"
        >
          Save as template
        </VBtn>
        <VBtn
          type="button"
          @click="saveTemplate(true)"
        >
          Save as template and send
        </VBtn>
        <VBtn type="submit">
          Send
        </VBtn>
      </VCardActions>
    </VForm>
  </VCard>
  <NotificationTemplateFormDialog
    v-model="notificationTemplateFormDialogOpen"
    :send="send"
  />
  <NotificationTemplatesDialog
    v-model="notificationTemplatesDialogOpen"
    @use="useTemplate"
  />
</template>
