import type {
  PaymentEstimateRequest,
  PaymentStoreRequest,
  PaymentValidateRequest,
} from '@api/requests/payment'
import type { PaymentEstimateResponse, PaymentStoreResponse, PaymentValidateResponse } from '@api/responses/payment'
import { BaseApiService } from '@api/services/api'

export class PaymentApiService extends BaseApiService {
  constructor() {
    super('payment')
  }

  public async currencies() {
    return super.request<string[]>(super.getUrl('currencies'))
  }

  public async validate(data: PaymentValidateRequest) {
    return super.request<PaymentValidateRequest, PaymentValidateResponse>(super.getUrl('validate'), {
      method: 'POST',
      data,
    })
  }

  public async estimate(data: PaymentEstimateRequest) {
    return super.request<PaymentEstimateRequest, PaymentEstimateResponse>(super.getUrl('estimate'), {
      method: 'GET',
      data,
    })
  }

  public async store(data: PaymentStoreRequest) {
    return super.request<PaymentStoreRequest, PaymentStoreResponse>(super.getUrl(''), {
      method: 'POST',
      data,
      retry: 5,
    })
  }

  public async cancel(invoiceId: number){
    return super.request(super.getUrl(`${invoiceId}/cancel`), {
      method: 'POST'
    })
  }
}
